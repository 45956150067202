import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { questions } from "../components/data/Data";
import Navbar from "../components/Navbar";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Formlabel,
  Button,
} from "@mui/material";

const Quiz = () => {
  const [formData, setFormData] = useState([]);
  const [done, setDone] = useState(false);
  const navigate = useNavigate();

  const radioSize = {
    "& .MuiSvgIcon-root": {
      fontSize: {
        xs: "16px",
        sm: "24px",
        md: "42px",
      },
    },
  };

  // const radioColors = [
  //   {
  //     color: pink[800],
  //     "&.Mui-checked": {
  //       color: pink[600],
  //     },
  //   },
  //   {
  //     color: pink[800],
  //     "&.Mui-checked": {
  //       color: pink[600],
  //     },
  //   },
  // ];

  const handleRadioChange = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index].answer = value;
    setFormData(updatedFormData);
    setDone(formData.every((data) => data.answer != ""));
    // console.log(done);
    // console.log(formData);
  };

  const MemoizedRadio = React.memo((props) => {
    return <Radio {...props} />;
  });

  React.useEffect(() => {
    setFormData(
      questions().map((question, i) => ({
        question,
        answer: "",
      }))
    );
  }, []);

  return (
    <div className="flex flex-col w-full px-4 py-20 md:px-14 lg:px-20">
      <div className="flex flex-col mb-10">
        <p className="block text-xl font-bold text-center mb-5 lg:text-left lg:text-2xl">
          The Okin Test
        </p>
        <p className="text-sm text-center md:text-base md:text-left">
          For the following 20 questions, you will be presented with a
          statement. Select how you feel about each of the statements, from
          strongly agree to neutral to strongly disagree. Upon completion of our
          Okin Test, you will be presented with careers you are most compatible
          with.
        </p>
      </div>

      <div className="relative flex flex-col">
        <FormControl>
          {formData.map((question, index) => (
            <div className="flex flex-col py-4 px-2 mb-5 rounded-xl bg-[#FFFFFF] justify-center drop-shadow-sm md:p-20">
              <div className="text-center mb-5">
                <span className="text-sm font-semibold lg:text-xl">
                  {question.question}
                </span>
              </div>

              {/* Mobile text */}
              <div className="flex justify-between px-6 md:hidden">
                <p className="flex items-center justify-start text-[10px] lg:text-lg">
                  Strongly Disagree
                </p>
                <p className="flex items-center text-end text-[10px] lg:text-lg">
                  Strongly Agree
                </p>
              </div>

              <div className="flex justify-center">
                <p className="hidden lg:flex items-center justify-start text-xs lg:text-lg">
                  Strongly Disagree
                </p>
                <RadioGroup
                  row
                  onChange={(e) => {
                    handleRadioChange(e.target.value, index);
                  }}
                >
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="1.428"
                    sx={{
                      color: "#AA1623",
                      m: 0,
                      "&.Mui-checked": {
                        color: "#AA1623",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "32px",
                          sm: "30px",
                          md: "40px",
                          lg: "47px",
                          xl: "4vw",
                        },
                      },
                    }}
                  >
                    Option 1
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="2.857"
                    sx={{
                      color: "#C63637",
                      "&.Mui-checked": {
                        color: "#C63637",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "28px",
                          sm: "24px",
                          md: "33px",
                          lg: "40px",
                          xl: "3.25vw",
                        },
                      },
                    }}
                  >
                    Option 2
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="4.285"
                    sx={{
                      color: "#E2504B",
                      "&.Mui-checked": {
                        color: "#E2504B",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "24px",
                          sm: "24px",
                          md: "26px",
                          lg: "33px",
                          xl: "2.5vw",
                        },
                      },
                    }}
                  >
                    Option 3
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="5.714"
                    sx={{
                      color: "#808080",
                      "&.Mui-checked": {
                        color: "#808080",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "20px",
                          sm: "24px",
                          md: "19px",
                          lg: "26px",
                          xl: "2vw",
                        },
                      },
                    }}
                  >
                    Option 4
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="7.142"
                    sx={{
                      color: "#1F7D1F",
                      "&.Mui-checked": {
                        color: "#1F7D1F",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "24px",
                          sm: "24px",
                          md: "26px",
                          lg: "33px",
                          xl: "2.5vw",
                        },
                      },
                    }}
                  >
                    Option 5
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="8.571"
                    sx={{
                      color: "#165816",
                      "&.Mui-checked": {
                        color: "#165816",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "28px",
                          sm: "24px",
                          md: "33px",
                          lg: "40px",
                          xl: "3.25vw",
                        },
                      },
                    }}
                  >
                    Option 6
                  </MemoizedRadio>
                  <MemoizedRadio
                    className="touch-manipulation"
                    value="10"
                    sx={{
                      color: "#0D340D",
                      "&.Mui-checked": {
                        color: "#0D340D",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "32px",
                          sm: "24px",
                          md: "40px",
                          lg: "47px",
                          xl: "4vw",
                        },
                      },
                    }}
                  >
                    Option 7
                  </MemoizedRadio>
                </RadioGroup>
                <p className="hidden lg:flex items-center text-end text-xs lg:text-lg">
                  Strongly Agree
                </p>
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            <Button
              variant="contained"
              onClick={() => navigate("/results", { state: formData })}
              disabled={!done}
              className=" w-40 h-14 justify-center"
            >
              Submit
            </Button>
          </div>
        </FormControl>
      </div>
    </div>
  );
};

export default Quiz;
