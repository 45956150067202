import React, { useRef, useState } from "react";
import data from "./data/data.json";
import { careers } from "./data/Data";
import {
  careerDesc,
  career1,
  career2,
  career3,
  career4,
  career5,
} from "../Assets/index";
import { useNavigate } from "react-router-dom";

const careerDescription = careerDesc;
const careerAvatars = [career1, career2, career3, career4, career5];

const QuizCalc = ({ formData }) => {
  const navigate = useNavigate();

  let result = [];
  careers().forEach((career) => result.push({ career: career, match: 0 }));

  //Results context
  // const { setCareer } = useContext(careerContext);

  //Calculate career weightage by quiz results => output to result array
  formData.forEach(({ question, answer }) => {
    data.forEach((item) => {
      if (question == item.Question) {
        item.careers.forEach(({ career, weightage }) => {
          // Testing
          // console.log(`
          // Career item loop
          // Career: ${career}
          // Answer: ${answer}
          // Weightage: ${parseFloat(parseFloat(answer / 10) * weightage)}
          // test: ${
          //   result[result.findIndex((item) => item.career == career)].match
          // }
          // `);
          result[result.findIndex((item) => item.career == career)].match +=
            parseFloat(parseFloat(answer / 10) * weightage);
        });
      }
    });
  });

  //Sort the results
  //[{career: careerName, match: matchPercentage(0.1)}]
  let sortedResults = result.sort((a, b) => b.match - a.match).slice(0, 5);
  // setCareer(sortedResults);

  //Click index
  const clickedIndex = useRef(0);

  //Show description bar
  const [showSidebar, setShowSidebar] = useState(false);

  //Define colour theme
  //bg-[#7FD6C2] bg-[#F6E049] bg-[#AAC6FC] bg-[#7FD6C2] bg-[#E0719E] hover:bg-[#7FD6C2] hover:bg-[#F6E049] hover:bg-[#AAC6FC] hover:bg-[#7FD6C2] hover:bg-[#E0719E]
  const colourTheme = ["#7FD6C2", "#F6E049", "#AAC6FC", "#7FD6C2", "#E0719E"];

  const [description, setDescription] = useState({
    career: sortedResults[0].career,
    match: sortedResults[0].match,
    colour: colourTheme[0],
    writeUp: [
      careerDescription["Accountant"].Description,
      careerDescription["Accountant"].Duties,
      careerDescription["Accountant"].Learning,
    ],
  });

  const getBackgroundColor = (index) => {
    console.log("GetBackgroundColour index: " + index);
    console.log("clickedIndex index: " + clickedIndex.current);
    if (clickedIndex.current == index) {
      return colourTheme[index];
    }
    return "";
  };

  const getSelected = (index) => {
    if (clickedIndex.current == index) {
      return "";
    }
  };

  const handleCareerClick = (event, index) => {
    //let careerId = event.currentTarget.id;
    // console.log("Index " + index);
    // console.log(description);
    // console.log("Career" + sortedResults[index].career);
    // console.log("Career" + careerDescription["Data Scientist"].Description);
    // console.log("Changing colour to " + description.colour);
    clickedIndex.current = index;
    setShowSidebar(!showSidebar);
    setDescription({
      ...description,
      career: sortedResults[index].career,
      colour: colourTheme[index],
      writeUp: [
        careerDescription[sortedResults[index].career].Description,
        careerDescription[sortedResults[index].career].Duties,
        careerDescription[sortedResults[index].career].Learning,
      ],
    });
  };
  return (
    <div className="flex pt-20 lg:pt-0">
      <div className="flex flex-col pt-[20px] px-5 lg:px-[55px] lg:pt-[70px] grow">
        {/* Header container */}
        <div className="lg:w-2/3 mb-11">
          <h2 className="text-[#263238] text-3xl text-center lg:text-left lg:text-[32px] font-[450] tracking-[-0.5px] mb-5">
            We identified 5 career paths which suit you the most
          </h2>
          <p className="text-[#6E767A] text-sm text-center lg:text-left">
            Select a career which you personally feel suits you more
          </p>
        </div>
        {/* Career results container */}
        <div
          id="noScroll"
          className="lg:max-h-[640px] lg:overflow-y-auto rounded-[25px]"
        >
          {sortedResults.slice(0, 5).map((item, index) => {
            return (
              <div
                key={index}
                className={`transition-colors duration-150 w-full h-[140px] lg:h-[175px] px-[14px] lg:px-[20px] py-[28px] mb-[20px] rounded-[50px] cursor-pointer bg-[${getBackgroundColor(
                  index
                )}] bg-opacity-[.29] hover:bg-[${
                  colourTheme[index]
                }] hover:bg-opacity-[.29]`}
                onClick={(event) => handleCareerClick(event, index)}
              >
                {/* Career container content */}
                <div className="w-full h-full flex items-center">
                  <div
                    className={`max-w-[96px] max-h-[96px] lg:max-w-[123px] lg:max-h-[123px] rounded-[37px] lg:rounded-[40px] bg-[${colourTheme[index]}] overflow-hidden`}
                  >
                    <img
                      src={careerAvatars[index]}
                      className="lg:h-[140px] lg:w-[140px]"
                    />
                  </div>
                  <div className="flex flex-col w-full grow pl-4 lg:pl-5">
                    <div className="flex justify-between h-[55px] lg:mb-[10px] text-[18px] lg:text-[20px] font-medium text-left">
                      {item.career}
                      <button
                        className={`hidden ${
                          clickedIndex.current == index
                            ? "lg:block"
                            : "lg:hidden"
                        } z-20 w-[75px] h-[40px] font-semibold rounded-[10px] text-[18px] hover:bg-[${
                          colourTheme[index]
                        }] hover:bg-opacity-[.5]`}
                        onClick={() => navigate("/maintenance")}
                      >
                        Select
                      </button>
                    </div>
                    {/* Bar */}
                    <div className="flex">
                      <div
                        className={`grow h-[21px] lg:h-[25px] rounded-[50px] justify-start items-center bg-[${colourTheme[index]}] bg-opacity-[.5]`}
                      >
                        <div
                          className={`h-full justify-start rounded-[50px] bg-[${colourTheme[index]}]`}
                          style={{ width: `${Math.round(item.match * 100)}%` }}
                        />
                      </div>
                      <p className="ml-5 font-bold text-[14.8px] lg:text-base">
                        {Math.round(item.match * 100)}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Career description sidebar (Desktop)*/}
      <div className="hidden w-[540px] h-full max-h-screen py-[73px] px-[55px] justify-center overflow-y-auto lg:block">
        <div
          className={`transition-color duration-300 w-[255px] h-[255px] bg-[${description.colour}] rounded-[50px] overflow-hidden lg:w-[420px] lg:h-[420px]`}
        >
          {/* Image */}
          <img src={careerAvatars[clickedIndex.current]} />
        </div>
        {/* Buttons */}
        <div className="flex justify-between mt-6">
          <button
            className={`transition-color duration-150 w-[121px] h-[40px] rounded-[25px] hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 px-4 text-[20px, 22px] text-black text-center">
              Description
            </div>
          </button>
          <button
            className={`transition-color duration-150 w-[140px] h-[40px] rounded-[25px] cursor-not-allowed hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 px-4 text-[20px, 22px] text-gray-400 text-center">
              Compatibility
            </div>
          </button>
          <button
            className={`transition-color duration-150 w-[121px] h-[40px] rounded-[25px] cursor-not-allowed hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 px-4 text-[20px, 22px] text-gray-400 text-center">
              Salary
            </div>
          </button>
        </div>

        {/* Description */}
        <div className="flex flex-col h-full w-full mt-10">
          <h2 className="font-semibold text ml-2 mb">About</h2>
          <div
            className={`transition-color duration-300 w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-black`}
          >
            {description.writeUp[0]}
          </div>
          <h2 className="font-semibold text ml-2 mb">Duties</h2>
          <div
            className={`transition-color duration-300 w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-black`}
          >
            {description.writeUp[1]}{" "}
          </div>
          <h2 className="font-semibold text ml-2 mb">Learning</h2>
          <div
            className={`transition-color duration-300 w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-black`}
          >
            {description.writeUp[2]}{" "}
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        className={`${
          showSidebar ? "" : "hidden"
        } lg:hidden fixed inset-0 bg-gray-600 opacity-50 z-30`}
        onClick={() => setShowSidebar(!showSidebar)}
      />
      {/* Career description sidebar (Mobile)*/}

      <div
        className={`${
          showSidebar ? "" : "hidden"
        } lg:hidden absolute z-40 w-[85%] inset-y-0 right-0 max-h-screen py-[40px] px-[20px] overflow-y-auto bg-white transition-all`}
      >
        <div
          className={`w-[255px] h-[255px] bg-[${description.colour}] rounded-[50px] overflow-hidden mx-auto`}
        >
          {/* Image */}
          <img src={careerAvatars[clickedIndex.current]} />
        </div>
        {/* Buttons */}
        {/* <div className="flex justify-between mt-6">
          <button
            className={`w-[30px] h-[40px] rounded-[25px] hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 text-[12px] text-black text-center">
              Description
            </div>
          </button>
          <button
            className={`w-[30px] h-[40px] rounded-[25px] cursor-not-allowed hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 px-4 text-[20px, 22px] text-gray-400 text-center">
              Compatibility
            </div>
          </button>
          <button
            className={`w-[30px] h-[40px] rounded-[25px] cursor-not-allowed hover:bg-[${description.colour}] hover:bg-opacity-[.29]`}
          >
            <div className="py-2 px-4 text-[20px, 22px] text-gray-400 text-center">
              Salary
            </div>
          </button>
        </div> */}

        {/* Description */}
        <div className="flex flex-col h-full w-full mt-10">
          <h2 className="font-semibold text ml-2 mb">About</h2>
          <div
            className={`w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-[14px] text-black`}
          >
            {description.writeUp[0]}
          </div>
          <h2 className="font-semibold text ml-2 mb">Duties</h2>
          <div
            className={`w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-[14px] text-black`}
          >
            {description.writeUp[1]}
          </div>
          <h2 className="font-semibold text ml-2 mb">Progression</h2>
          <div
            className={`w-full h-full bg-[${description.colour}] bg-opacity-[.29] p-6 mb-6 rounded-[25px] text-[14px] text-black`}
          >
            {description.writeUp[2]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizCalc;
