import { MenuItem } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import { FaHome, FaBook, FaEdit, FaBullseye, FaUserAlt } from "react-icons/fa";

const SidebarMenu = () => {
  const menuItem = [
    {
      path: "https://okin.ai/",
      name: "Dashboard",
      icon: <FaHome />,
      disabled: false,
      //suffix: ""
    },
    {
      path: "/test",
      name: "Okin Test",
      icon: <FaEdit />,
      disabled: false,
      //suffix: ""
    },
    // {
    //   path: "/results",
    //   name: "Results",
    //   icon: <FaBook />,
    //   disabled: false,
    //   //suffix: ""
    // },
    // {
    //   path: "/careers",
    //   name: "Careers",
    //   icon: <FaBullseye />,
    //   disabled: true,
    //   //suffix: ""
    // },
    // {
    //   path: "/profile",
    //   name: "Profile",
    //   icon: <FaUserAlt />,
    //   disabled: true,
    //   //suffix: ""
    // },
  ];

  return menuItem.map((item) => (
    <MenuItem
      className={item.disabled ? "hidden" : ""}
      key={item.name}
      icon={item.icon}
    >
      {item.name}
      <NavLink to={item.path} />
    </MenuItem>
  ));
};

export default SidebarMenu;
