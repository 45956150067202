import React from 'react';
import data from "../components/data/data.json"

const Careers = () => {
    return (
        <div>
            {
                data.map((arr, index) => (
                    <div>
                        <h2 key={index}>{arr.Question}</h2>
                        {arr.careers.map(arr1 => (
                            <div>
                                <p>{arr1.career}</p>
                                <p>{arr1.weightage}</p>
                            </div>
                        ))}
                    </div>
                ))
            }
        </div>
    );
};

export default Careers;