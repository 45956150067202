import React from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaInstagramSquare,
  FaLinkedin,
  FaUser,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import SidebarMenu from "./SidebarMenu";

const Sidebar = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
      className="border border-slate-800"
    >
      {/* SIDEBAR HEADER */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight />}
              onClick={handleCollapsedChange}
            />
          ) : (
            <MenuItem onClick={handleCollapsedChange}>
              <div
                className="flex justify-center p-2 font-bold text-3xl tracking-[0.25rem]"
                id="logo"
              >
                OKIN
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>

      {/* SIDEBAR MAIN */}
      <SidebarContent>
        <Menu iconShape="circle">
          <SidebarMenu />
        </Menu>
      </SidebarContent>

      {/* SIDEBAR FOOTER */}
      <SidebarFooter style={{ textAlign: "justify" }}>
        <div className="sidebar-btn-wrapper" style={{ padding: "16px" }}>
          <Link
            className="sidebar-btn"
            style={{ cursor: "pointer" }}
            to="https://www.linkedin.com/company/okin-technologies/"
            target="_blank"
          >
            <FaLinkedin />
          </Link>
          <Link
            className="sidebar-btn"
            style={{ cursor: "pointer" }}
            to="https://www.instagram.com/okin.ai/"
            target="_blank"
          >
            <FaInstagramSquare />
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
