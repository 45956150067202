import React from "react";
import QuizCalc from "../components/QuizCalc";
import { useLocation } from "react-router-dom";

const Results = () => {
  // Retrieve state from handleSubmit function in Quiz.jsx
  const { state } = useLocation();

  const ResultsOut = () => {
    console.log(state);
    return state == null ? (
      <div className="px-20 pb-20 text-center">
        <h1>DO THE QUIZ</h1>
      </div>
    ) : (
      <div className="h-screen">
        <QuizCalc formData={state} />
      </div>
    );
  };

  return <ResultsOut />;
};

export default Results;
