import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "./css/style.css";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import Results from "./pages/Results";
import Quiz from "./pages/Quiz";
import Careers from "./pages/Careers";
import Profile from "./pages/Profile";
import Maintenance from "./pages/Maintenance";

import "./styles.scss";
//import logo from "./Assets";

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""} antialiased`}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <div className="w-full overflow-y-auto">
        <Navbar handler={handleToggleSidebar} />
        <main>
          <Routes>
            <Route path="/" element={<Quiz />} />
            <Route path="/test" element={<Quiz />} />
            <Route path="/results" element={<Results />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/maintenance" element={<Maintenance />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
