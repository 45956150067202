import React from "react";
import { FaBell, FaUser, FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Navbar = ({ handler }) => {
  return (
    <nav className="lg:hidden flex top-0 absolute inset-x-0 h-20 mb-5 pr-10 lg:pr-20 pl-5 items-center">
      <div className="btn-toggle" onClick={() => handler(true)}>
        <FaBars size="43" />
      </div>
      <div className="flex ml-[190px] lg:ml-auto w-1/6 justify-between items-center">
        {/* <p className="font-semibold whitespace-nowrap mr-4 items-baseline">
          Kai Yuan
        </p>
        <NavLink to="/profile">
          <FaUser className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-300" />
        </NavLink> */}
      </div>
    </nav>
  );
};

export default Navbar;
