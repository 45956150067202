import React from "react";

const Maintenance = () => {
  return (
    <div className="flex flex-col">
      <div className="mx-auto grow mt-[250px]">
        <img src={require("../Assets/maintenance.png")} />
      </div>
      <div>
        <h1 className="antialiased tracking-[1px] mt-10 text-center font-semibold text-[36px]">
          Hang on! We are under maintenance 🛠️
        </h1>
      </div>
    </div>
  );
};

export default Maintenance;
